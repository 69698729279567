.main.auth {
  background-color: #F2F7FF;
  padding-top: 26px;
  padding-bottom: 26px;
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    max-height: 753px;
    display: flex;

    .background {
      color: black;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .join-us-text {
        color: #0048B2;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
      }
    }

    .content {
      min-width: 462px;
      max-width: 462px;
      width: 462px;
      background-color: white;
      padding-left: 68px;
      padding-right: 68px;
      padding-top: 40px;
      @import "auth/signin";

      .buttons {
        margin-top: 10px;
        margin-bottom: 10px;
        height: 42px;
        background-color: #DFEAFF;
        border-radius: 53px;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;

        a {
          width: 50%;
          align-items: center;
          height: 36px;
          display: flex;
          font-size: 14px;
          line-height: 21px;
          justify-content: center;
          color: #1D6AFF;

          svg {
            margin-right: 12px;
          }

          &:hover {
            text-decoration: none;
          }

          &.active {
            background-color: #1D6AFF;
            box-shadow: inset -1px 1px 4px rgba(0, 0, 0, 0.1);
            color: white;
            border-radius: 20px;
          }
        }
      }
    }
  }
}