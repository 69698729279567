.form-header {
  color: #003CB0;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  margin-bottom: 15px;
}

.form-field {
  margin-top: 10px;
  margin-bottom: 30px;

  &.switch {
    display: flex;
    flex-direction: row;

    label {
      flex-grow: 1;
    }
  }

  .MuiTextField-root, .MuiFormControl-root {
    width: 100%;
    background-color: white;

    label.MuiFormLabel-filled, label.Mui-focused {
      padding-left: 2px;
      background-color: white;
      padding-right: 2px;
    }

    legend {
      width: 0;
    }

    .MuiInputLabel-formControl {
      &:not(.Mui-focused):not(.MuiFormLabel-filled) {
        font-size: 14px;
        top: -2px;
      }
    }

    .MuiInputBase-root {
      background-color: white;

      .MuiSelect-select {
        font-size: 14px;
        padding-top: 10px;
      }

      fieldset {
        border: 1px solid #EDEEF1 !important;
        border-radius: 5px;
      }

      &:not(.MuiInputBase-multiline) {
        fieldset {
          max-height: 52px;
        }
      }

      input {
        height: 14px;
        font-size: 14px;
      }

      &:not(.Mui-focused):not(.Mui-error) {
        input:-webkit-autofill {
          -webkit-box-shadow: 0 0 0 30px white inset !important;
        }
      }

      &.Mui-focused {
        fieldset {
          border: 1px solid #0057FF !important;
        }

        input {
          border-radius: 5px;
          background-color: #F8FAFF;
          height: 14px;
        }

      }

      &.Mui-filled {
        background-color: white;

        fieldset {
          border: 1px solid #0057FF !important;
        }

      }

      &.Mui-error {
        fieldset {
          border: 1px solid #F24D38 !important;
        }

        input {
          height: 14px;
          border-radius: 5px;
          background-color: #FFF8F8;
        }
      }

    }

  }

  .MuiAutocomplete-root {
    &.Mui-focused {
      .MuiFormControl-root {
        .MuiOutlinedInput-root {
          padding: 0;

          input {
            padding-left: 14px;
            height: 34px;
            font-size: 14px;
          }
        }
      }
    }
  }

  .information-text, .validation-text, .warning-text {
    color: #9FA1B1;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    padding-left: 8px;
    margin-bottom: 0;
    height: 24px;
    display: flex;
    align-items: center;

    svg {
      color: #1D6AFF;
      margin-right: 5px;
    }
  }

  .validation-text {
    color: #F24D38;

    svg {
      color: #F24D38;
      margin-right: 5px;
    }
  }

  .warning-text {
    color: #FFC700;

    svg {
      color: #FFC700;
      margin-right: 5px;
    }
  }

  .checkbox {
    display: flex;
    color: black;

    .checkbox-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      width: 24px;
      height: 24px;
      border: 1px solid #1D6AFF;
      margin-right: 16px;
    }
  }

  &.date-input {
    position: relative;

    .datepicker {
      position: absolute;
      z-index: 999;
    }
  }
}

.form-skeleton {
  margin-top: 6px;
}


.checkbox {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #1D6AFF;
  margin-bottom: 16px;

  .checkbox-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    border: 1px solid #1D6AFF;
    margin-right: 16px;
  }

  .checked {
    background-color: #B1C8F5;
  }

  span {
    font-size: 12px;
  }
}

.inavailable {
  color: #FFC700;
}

.MuiMenuItem-root.current {
  display: none;
}

.detailed-select-option {
  display: flex;
  flex-direction: column;
  padding: 3px;
  width: 100%;

  &.multi {
    flex-direction: row;
    align-items: center;

    .checkbox {
      margin-bottom: 0;
    }
  }

  &.current {
    display: none !important;
  }

  &.partners {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .name {
    color: #002678 !important;
    font-size: 12px;
    line-height: 20px;

    .inavailable {
      float: right;
    }
  }

  .details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #7B90BC !important;
    font-size: 10px;
    line-height: 15px;

    .right {
      float: right;
    }
  }
}

.MuiList-root, .MuiAutocomplete-listbox {
  cursor: pointer;

  .select-option {
    color: #002678 !important;
    font-size: 12px;
    line-height: 20px;
  }

  & > div, & > li {
    &:nth-child(even) {
      background: #F7FAFF;
    }
  }

  & > div:hover {
    background-color: lightblue;
  }
}

.duration-field {
  display: flex;
  align-items: center;

  .MuiFormControl-root {
    max-width: 90px;
    min-width: 90px;
  }

  label {
    margin-right: 20px;
  }

  .inputs {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    .selects {
      width: 100%;
      display: flex;
    }
  }

  .form-field {
    margin: 0;
  }
}