.reservation-modal {
  top: 5px;
  width: 600px;

  .navigation {
    display: flex;
    height: 1px;
    border: 1px solid;
    margin-bottom: 50px;
    position: relative;
    margin-left: 30px;
    margin-right: 30px;
    justify-content: space-between;

    div {
      border-radius: 10px;
      border: 4px solid red;
      margin-top: -10px;
      height: 20px;
      width: 20px;
      background-color: white;
    }
  }

  .reservation-time-form {
    display: flex;
    flex-direction: row;

    .form-field {
      width: 50%;
    }
  }

  .footer {
    position: absolute;
    bottom: 50px;
    justify-content: space-evenly;
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  div[role="radiogroup"] {
    flex-direction: row;
  }

  .add-client-btn {
    background-color: white !important;
    color: #1D6AFF !important;
    padding-bottom: 2px;
    padding-top: 2px;
    height: 12px;
    font-weight: normal;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  .client-select {
    margin-bottom: 10px;
  }

  .header {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    color: rgba(43, 48, 70, 1) !important;
    grid-row: 1;

    .edit, .remove, .closee, .confirm {
      color: rgba(43, 48, 70, 1) !important;
      width: 48px;
      height: 48px;
      justify-content: center;
      display: flex;
      align-items: center;
      margin-left: 5px;
      cursor: pointer;
    }

    .edit, .remove, .confirm {
      border: 1px solid rgba(222, 223, 227, 1);
      border-radius: 12px;
    }
  }

  &.MuiModal-root {
    left: unset !important;
    padding: 0;

    & > .MuiDialog-container {
      & > .MuiPaper-root {
        padding: 30px;
        max-height: 100%;
        height: calc(100vh - 10px);
        margin: 0 !important;
        width: 100% !important;
        display: grid;
        grid-template-rows: auto 1fr auto;

        &.MuiAutocomplete-paper {
          height: auto;
        }
      }
    }
  }

  .init-page {
    display: flex;
    flex-direction: column;
    padding-top: 40px;

    .menuitem {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      border: 1px solid #EAEBEB;
      border-radius: 5px;
      margin-bottom: 20px;
      padding: 15px 10px;
      justify-content: center;
      align-content: center;
      align-items: center;

      .info {
        display: flex;
        flex-direction: column;
        margin-left: 10px;

        .header {
          font-weight: bold;
          font-size: 15px;
          color: black;
        }

        .infotext {
          font-size: 13px;
          color: #808493;
        }
      }
    }
  }

  .cycle-term-list {
    .invalid-terms {
      margin-bottom: 40px;
    }

    .valid-terms {

    }

    .term {
      width: 100%;
      box-shadow: 0px 12px 24px 0px rgba(20, 20, 43, 0.04);
      box-shadow: 0px -2px 4px 0px rgba(20, 20, 43, 0.02);
      box-shadow: 0px 3px 14px 0px rgba(74, 58, 255, 0.03);
      border-radius: 18px;
      padding: 5px;
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 5px;
      justify-content: space-between;
      //background-color: lightgreen;
      .counter {
        font-size: 14px;
        margin-right: 15px;
      }

      .date, time, .info {
        color: rgba(111, 108, 144, 1);
        font-size: 15px;
      }

      .time {
        margin-left: 10px;
      }

      .price {
        color: rgba(23, 15, 73, 1);
        font-size: 16px;
        font-wegiht: bold;
      }

      &.invalid {
        background-color: rgba(255, 214, 220, 1);
      }

      .actions {
        svg {
          margin-left: 10px;
        }
      }
    }

    .proposals {
      .error {
        color: red;
        font-weight: bold;
        font-size: 12px;
        text-align: center;
      }

      .slots {
        flex-wrap: wrap;

        .slot {
          font-size: 12px;
          background-color: blue;
          margin: 3px;
          color: white;
          padding: 5px;
          border-radius: 5px;
          min-width: 85px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }

  .content {
    grid-row: 2;
    overflow-y: auto;
  }

  .dialog-footer {
    margin-top: 20px;
  }
  .single-rental-reservation-form {
    .form-field {
      margin-bottom: 0 !important;
    }
  }

  .edit-single-rental-reservation-form {
    .cycle-term-list {
      & > .term.active {
        background-color: lightseagreen;
      }
      & > .term:hover {
        background-color: lightblue;
        cursor: pointer;
      }
    }
    .form-field {
      margin-bottom: 0 !important;
    }
  }
}