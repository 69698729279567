.react-calendar, .MuiPickerStaticWrapper-root {
  outline: none !important;
  padding-top: 21px;
  font-family: Poppins;
  border: none;
  box-shadow: -1px 0px 2px rgba(229, 238, 255, 0.7), 0px 69px 22px rgba(0, 112, 219, 0.02), 0px 20.8015px 6.63236px rgba(0, 112, 219, 0.0130318), 0px 8.63987px 2.75474px rgba(0, 112, 219, 0.01), 4px 0px 12px rgba(0, 112, 219, 0.06);
  border-radius: 6px;
  &>div>div {
    margin: 0;
  }
  .react-calendar__navigation__next2-button, .react-calendar__navigation__prev2-button {
    display: none;
  }

  .react-calendar__month-view__weekdays {
    color: #5F5F5F;
    font-size: 12px;
    cursor: pointer;
  }

  .react-calendar__navigation__arrow {
    color: lightgray;
    font-size: 40px;
    outline: none;

    &:hover {
      background-color: white;
    }
  }

  .react-calendar__navigation__label {
    outline: none;

    &:hover {
      background-color: white;
    }
  }

  .react-calendar__navigation__label__labelText {
    color: #0057D9;
    font-size: 14px;
    font-weight: bold;
  }

  .react-calendar__tile {
    font-size: 12px;
    color: #003CB0;
    font-weight: bold;
    outline: none;

    &:hover {
      border-radius: 16px;
      background: rgba(67, 130, 255, 0.15);
    }
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: #A8BFD4;
  }

  .react-calendar__tile--active {
    outline: none;
    border-radius: 16px;
    background: rgba(67, 130, 255, 0.15);
  }

  .react-calendar__month-view__weekdays__weekday {
    abbr {
      border-bottom: none !important;
      cursor: inherit !important;
      text-decoration: none !important;
    }
  }
}