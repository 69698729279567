.opening-hours-table {
  font-size: 14px;
  font-weight: 400;
  color: #839AC7;
  width: 100%;
  margin-top: 25px;
  thead {
    th {
      color: #839AC7;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
    }
  }
  tbody {
    td {
      border-top: none;
    }

    tr:nth-child(odd) {
      background-color: #F7F9FF;
    }

    tr:nth-child(even) {
      background-color: white;
    }
  }

  .time-input {
    height: 30px;
    max-width: 54px;

    .MuiInputBase-root {
      display: flex;
      justify-content: center;
      align-content: center;
      padding: 0 !important;
      height: 30px;
      border-radius: 5px;

      input {
        text-align: center;
        padding: 0;
        height: 30px;
        font-size: 12px;
        color: #5E79AC;
        width: 100%;
      }

      fieldset {
        top: 0;
        height: 30px;
        border: 0.5px solid #839AC7;

        legend {
          display: none;
        }
      }

    }

    .MuiAutocomplete-clearIndicator {
      display: none;
    }

    .MuiAutocomplete-popupIndicator {
      display: none;
    }

    .Mui-disabled, &.disabled {
      input {
        color: #B3C4E7;
      }
      fieldset {
        border: 0.5px solid #A3B5D8;
      }
    }
    .Mui-focused {
      input {
        color: #5E79AC;
      }
      fieldset {
        border: 0.5px solid #598FFF;
      }
    }
  }

  .hour-row {
    display: flex;
    flex-direction: row;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .time-input {
      margin-right: 10px;
    }

    svg {
      cursor: pointer;
      margin-right: 8px;
      color: #1D6AFF;
      background-color: white;
    }

    .MuiAutocomplete-popper {

      li {
        font-size: 12px;
        padding: 0;
        display: flex;
        justify-content: center;
      }
    }

  }

}