.help-guide {

  .dropdown-menu {
    min-width: 283px;
    background-color: #E4EFFF;
    padding: 13px;

    .c1 {
      margin: 0;
      display: flex;
      flex-direction: column;
      padding-top: 20px;
      padding-bottom: 23px;

      .header {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 700;
        margin: 0;
        padding-bottom: 24px;
        color: #073F9A;

        .icon {
          margin: 0;
          margin-right: 15px;

          svg {
            margin: 0;
          }
        }

      }

      .content-text {
        color: #0049C0;
        font-weight: 400;
        padding-left: 41px;
        font-size: 12px;
        padding-bottom: 30px;
      }

      .more {
        color: #00A3FF;
        width: 100%;
        padding-left: 31px;
        display: flex;
        flex-direction: row;
        cursor: pointer;

        svg {
          margin-right: 9px;
        }

      }

    }

  }

}