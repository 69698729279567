.creator-page {
  min-width: 100%;
  min-height: 100vh;
  border: 1px solid #DFEAFF;

  .content {
    background: #FFFFFF;
    box-shadow: 0px 0px 5px rgba(0, 87, 255, 0.05), 0px 25px 35px rgba(48, 81, 147, 0.08);
    border-radius: 10px;
    margin: 24px;
    min-height: calc(100vh - 50px);

    .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 30px;

      .header-text {
        font-weight: bold;
        font-size: 16px;
        line-height: 32px;
        color: #003CB0;
      }

      //background-color: #DFEAFF;

      .navigation {
        display: flex;
        flex-direction: row;
        margin-top: 50px;

        svg {
          color: #E2ECFF;
          //position: absolute;
          //left: 0;
          //top: 0px;
        }

        .navigation-item {
          padding-left: 10px;
          padding-right: 10px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          max-width: 109px;
          color: #ADCAFF;
          font-size: 10px;
          line-height: 14px;
          position: relative;

          .number {
            div {
              background: #F2F7FF;
              border-radius: 25px;
              min-width: 24px;
              min-height: 24px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 16px;
              //line-height: 32px;
              color: #1D6AFF;
            }
          }

          .text {
            margin-top: 8px;
            color: #ADCAFF;
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 14px;
            text-align: center;
          }

          &.active {
            .number {
              div {
                color: #E0ECFF;
                background-color: #003CB0;
              }
            }

            .text {
              color: #2D55FB;
            }
          }

          &.completed {
            .number {
              div {
                color: white;
                background-color: #1ACBAA;
              }
            }

            .text {
              color: #1ACBAA;
            }
          }
        }
      }

      .footer {
        margin-top: 60px;
        display: flex;
        flex-direction: row;
        color: black;
        justify-content: center;

        .next, .prev {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 48px;
          height: 48px;
          border-radius: 40px;
          cursor: pointer;
        }

        .prev {
          margin-right: 16px;
          background-color: white;
          color: #1D6AFF;
          border: 1px solid #1D6AFF;
        }

        .next {
          color: white;
          background-color: #1D6AFF;
          margin-left: 16px;
        }

        button {
          background-color: #1D6AFF;
          color: white;
          text-transform: none;
          font-size: 14px;
          line-height: 21px;
          font-weight: 600;
          height: 46px;
          padding-left: 30px;
          padding-right: 20px;

          svg {
            margin-left: 10px;
          }
        }
      }

      .content-data {
        min-height: 320px;
        margin-top: 75px;
        padding-left: 100px;
        padding-right: 100px;
        background: #FFFFFF;
        box-shadow: 0px 0px 5px rgba(0, 87, 255, 0.05), 0px 25px 35px rgba(48, 81, 147, 0.08);
        border-radius: 16px;
        min-width: 506px;

        .header-text {
          text-align: center;
          margin-top: -16px;
          color: #003CB0;
          font-size: 16px;
          font-weight: 600;
        }

        form {
          padding-top: 60px;
          padding-bottom: 60px;
        }

        &.step-2 {
          min-width: 800px;
        }

        &.step-3 {
          padding-left: 15px;
          padding-right: 15px;
          .opening-hours-footer {
            display: flex;
            padding-bottom: 10px;
            button {
              flex: 1;
            }
          }
        }
      }

      .step-complete {
        text-align: center;

        div:first-child {
          color: #003CB0;
          font-size: 38px;
          line-height: 46px;
          font-weight: 700;
        }

        div:nth-child(2) {
          font-size: 14px;
          color: #0048B2;

          span {
            font-weight: 700;
            color: #003CB0;
          }
        }
      }
    }
  }

  .employees {

    margin-top: 10px;

    .employee-list {
      color: #00359B;
      .item {
        padding-bottom: 12px;
        padding-top: 12px;
        padding-left: 10px;
        display: flex;
        flex-direction: row;
        &:nth-child(odd) {
          background-color: #F7F9FF;
        }
        .employee-data {
          display: flex;
          flex-direction: column;
          flex: 1;
          .name {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
          }
          .role {
            font-style: normal;
            font-weight: 300;
            font-size: 10px;
            line-height: 16px;
          }
        }
        .price {
          display: flex;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          margin-right: 30px;
          align-items: center;
          justify-content: center;
        }
        .buttons {
          display: flex;
          flex-direction: row;
          align-items: center;
          svg {
            color: #1D6AFF;
            cursor: pointer;
            margin-right: 10px;
          }
        }
      }

    }

    .add-employee {
      margin-top: 15px;
      margin-bottom: 15px;
      display: flex;
      flex-direction: row;
      cursor: pointer;
      color: #1D6AFF;
      font-size: 14px;
      font-weight: 600;
      align-items: center;

      .icon {
        height: 25px;
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .text {

      }

    }

  }

}