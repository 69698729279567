nav {

  @import 'navbar/helpguide';

  @import 'navbar/notifications';
  width: 100%;
  background-color: white;
  height: 76px;
  padding-bottom: 14px;
  padding-top: 14px;
  padding-right: 22px;
  box-shadow: 0px 4px 4px rgba(192, 214, 255, 0.17);

  .left-panel {
    width: 100%;
    .breadcrumbs {
      display: flex;
      flex-direction: row;
      align-items: center;
      .currentsitename {
        margin-left: 40px;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #1B489F;
      }
    }
  }

  .right-panel {
    display: flex;
    min-width: 162px;
  }

  .navbar-collapse {
    color: green;
  }

  .dropdown-menu {
    min-width: 211px;
    font-size: 14px;
    font-weight: 700;
    color: #1B489F;
    border-radius: 15px 0 15px 15px;
    border: 0.5px solid #97BAFF;

    .menu-list {
      margin: 0 10px 0 10px;

      button {
        height: 36px;
        font-size: 14px;
        font-weight: 700;
        color: #1B489F;
        border-radius: 5px;

        &:hover {
          background-color: #E3EFFF;
        }

        span {
          padding-left: 16px;
        }

      }

    }

    .dropdown-divider {
      height: 0;
      padding: 0;
      margin-top: 6px;
      margin-bottom: 6px;
    }

  }

  .navbar-collapse:not(.settings) {

    margin-right: 4px;
    margin-left: 4px;

    .nav-link {
      max-height: 25px;
      max-width: 25px;
      background-color: #E5EEFF;
      border-radius: 40px;

      &:hover, &:is(.show) {
        background-color: #003FB9;
        color: white;

        svg {
          color: white;
        }

      }

    }

    .nav-link {
      padding: 0;
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

  }

  .settings {

    position: relative;

    .avatar {
      max-height: 48px;
      height: 100%;
      width: 100%;
    }

    .avatar-container {
      position: relative;
      height: 100%;
      width: 100%;
    }

    .active {
      position: absolute;
      height: 21px;
      width: 21px;
      right: -3px;
      bottom: -3px;
      border-radius: 21px;
      border: 3px solid white;
      background-color: #6FCF97;
    }

    .dropdown-toggle {
      color: #0094FF;
    }

    .nav-link {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      height: 48px;
    }

  }

}