.sidebar {
  min-height: 100%;
  background-color: #274DD8;
  font-size: 16px;
  min-width: 248px;
  width: 238px;
  z-index: 1000;

  @import 'sidebar/header';

  @import 'sidebar/search';

  .sidebar-nav {

    .sidebar-item {
      color: #C0D6FF;
      min-height: 40px;
      position: relative;

      &.active {

        &::before {
          content: '';
          height: 32px;
          background-color: #FF8080;
          width: 5px;
          position: absolute;
          top: 4px;
          left: 0;
          border-radius: 5px;
        }

        .sidebar-item-header {
          color: white;
          font-weight: 700;
        }

        .arrow {
          transform: rotate(180deg);
        }

      }

      &.open {
        .arrow {
          transform: rotate(180deg);
        }
      }

      &:hover {
        .sidebar-item-header {
          cursor: pointer;
          background-color: #1D6AFF;
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
        }
      }

      .sidebar-item-header {
        min-height: 40px;
        display: flex;
        align-items: center;
        padding-left: 21px;
        margin-right: 10px;
        text-decoration: none;
        color: inherit;

        .sidebar-text {
          padding-left: 17px;
        }

        .arrow {
          transition: transform .2s ease-in-out;
          position: absolute;
          right: 27px;
        }

      }

      .collapse, .collapsing {
        padding-left: 64px;
        padding-bottom: 4px;

        .sidebar-list-item {
          color: #6499FF;
          height: 22px;
          padding-top: 4px;
          a {
            color: inherit;
            text-decoration: none;
          }

          &:hover, &.active {
            color: #96CBFF;
            cursor: pointer;
          }

          &.active::before {
            content: '>';
            position: absolute;
            left: 45px;
          }
        }
      }

    }

  }

  @import 'sidebar/toggled';

}