.gallery-editor {
    box-shadow: 0px 0px 5px rgba(0, 87, 255, 0.03), 0px 25px 35px rgba(48, 81, 147, 0.04);
    border-radius: 10px;
    border: 1px solid #F7F7F7;
    padding: 27px 33px;

    .col {
        padding: 4px;
    }
    .tile {
        width: 100%;
        height: 100%;
        padding: 9px;
        img {
            width: 100%;
            border-radius: 4px;
            object-fit: cover;
            height: 100%;
            max-height:165px;
        }
        .mainimage {
            background-color: #E2ECFF;
            position: absolute;
            top: 4px;
            color: #002678;
            font-weight: 500;
            font-size: 17px;
            line-height: 15px;
            padding: 3px 10px;
            border-radius: 3px;
        }
        .delete-btn {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            .icon {
                border-radius: 100px;
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: white;
                cursor: pointer;
                svg {
                    color: red;
                }
            }
        }
        &.image {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: flex-start;
        }
        .add {
            width: 100%;
            height: 100%;
            background-color: #F1F6FF;
            border: 0.5px solid #1D6AFF;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            cursor: pointer;
            input[type="file"] {
                display: none;
            }
            span {
                color: #00359B;
                font-weight: 500;
                font-size: 15px;
                line-height: 15px;
                margin-top: 10px;
            }
            svg {
                color: #00359B;
            }
        }
        &.placeholder {
            cursor: default;
        }
    }
}