.list-page {
  .object-list {
    .facility {
      background-color: #FFFFFF;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      padding: 40px 16px 16px;
      box-shadow: 0px 0px 5px rgba(0, 87, 255, 0.05), 0px 25px 35px rgba(48, 81, 147, 0.08);
      border-radius: 5px;

      a {
        text-decoration: none;

        button {
          width: 100%;
        }
      }

      .title {
        color: #0038B0;
        font-weight: bold;
      }

      .type {
        color: #002678;
        font-weight: bold;
      }

      .address {
        min-height: 18px;
        color: #839AC7;
        font-weight: 300;
        font-size: 12px;
        line-height: 18px;
      }

      .img {
        width: 100%;
        max-height: 200px;
        min-height: 200px;
        object-fit: cover;
        border-radius: 5px;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      button {
        margin-top: 10px;
        background-color: #E3EFFF !important;
        color: #1D6AFF !important;
      }
    }
  }
}