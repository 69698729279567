&.toggled {
  width: 40px;
  min-width: 40px;

  .sidebar-header {

    img {
      display: none;
    }

  }

  .hamburger {
    display: flex;
    justify-content: center;
    padding-right: 0;
  }

  .sidebar-header {
    padding-left: 0;
    padding-right: 0;
  }

  .sidebar-search {
    padding-left: 2px;
    padding-right: 2px;

    input {
      &::placeholder {
        color: transparent;
      }
    }

    svg {
      left: 12px;
    }
  }

  .sidebar-nav {

    .sidebar-item {

      height: 40px;
      padding-bottom: 2px;

      .sidebar-item-header {

        padding-left: 0;
        display: flex;
        justify-content: center;
        margin-right: 0;

        .sidebar-text {
          display: none;
        }

        .arrow {
          display: none;
        }

        &:hover {
          cursor: pointer;
          background-color: #1D6AFF;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

      }

      .collapse,.collapsing {
        display: none;
        background-color: #274DD8;
        width: 204px;
        padding-left: 32px;
        border-bottom-right-radius: 10px;
      }

      &:hover {
        width: 244px;
        .sidebar-item-header {
          padding-left: 12px;
          border-top-right-radius: 20px;
          border-bottom-right-radius: 0;
          background-color: #1D6AFF;
          justify-content: left;
        }
        .collapse {
          padding-bottom: 0;
          width: 244px;
          padding-left:60px;
          display: block;
          border-bottom-right-radius: 20px;
          .sidebar-list-item {
            display: flex;
            padding-top: 0;
            align-items: center;
            height: 40px;
            a {
              color: inherit;
              text-decoration: none;
            }
          }
          .sidebar-list-item:last-child {
            border-bottom-right-radius: 20px;
          }
        }
        .sidebar-text {
          display: block;
        }
      }

      &:not(.has-child) {
        .sidebar-item-header {
          border-bottom-right-radius: 20px;
        }
      }

    }
  }

}