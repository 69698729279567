.gallery-editor {
  color: #6982B4;
  .image-list {
    .image-item {
      padding: 20px;
      margin-bottom: 10px;
      border-radius: 10px;
      border: 1px solid #6982B4;
      display: flex;
      flex-direction: row;
      .image {
        width: 250px;
        display:flex;
        justify-content: center;
        img {
          border-radius: 10px;
        }
      }
      .details {
        padding-left: 10px;
        flex-grow: 1;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
      .actions {
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          cursor: pointer;
        }
        .delete {
          color: red;
        }
        .as-main {
          color: lightblue;
        }
        .main-image {
          color: green;
        }
      }
    }
  }
}