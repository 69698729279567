.dashboard-page {
  height: 100%;
  margin: 10px;
  border-radius: 10px;
  border: 1px solid;
  background-color: white;
}

.calendar-navigation {
  //background-color: red;
  display: flex;
  flex-direction: row;
  padding-left: 46px;

  .add-button {
    svg {
      color: #5F9FFF;
    }

    color: white;
    font-size: 14px;
    font-weight: 700;
    background-color: #1D6AFF;
    border: none;
    border-radius: 5px;
    padding: 10px 16px;
    min-width: 95px;
    cursor: pointer;
    margin-right: 12px;
  }

  .navigation {

    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 44px;
    width: 100%;

    button {
      background-color: #F2F7FF;
      border-radius: 5px;
      border: none;
      color: #5F9FFF;
      font-size: 14px;
      font-weight: 500;
      outline: none;
    }

  }

}

.calendar-filter-panel {
  overflow-y: auto;
  overflow-x: hidden;
  color: black;
  width: 303px;
  //min-width: 303px;
  min-width: 348px;
  background-color: #FFFFFF;
  height: 100%;
  max-height: 99vh;
  min-height: 99vh;
  transition: margin-left 0.5s;
  position: relative;
  box-shadow: 0px 4px 8px rgba(192, 214, 255, 0.36);
  padding-right: 12px;

  .filter-panel-content {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 7px;
    padding-right: 12px;
    padding-bottom: 50px;
  }

  &.hidden {
    margin-left: -355px;
  }

  .search {

    margin-top: 8px;
    font-size: 12px;
    color: #1D6AFF;
    position: relative;
    margin-bottom: 14px;

    input {

      width: 100%;
      height: 40px;
      background-color: #EEF4FF;
      border: none;
      padding-left: 24px;
      border-bottom-right-radius: 15px;
      border-top-right-radius: 15px;

      &:focus-visible {
        border: none;
        outline: none;
      }

      &::placeholder {
        color: #1D6AFF;
      }

    }

    svg {
      position: absolute;
      right: 12px;
      top: 10px;
    }

  }

  .filter {
    padding: 5px;

    .header {
      font-size: 14px;
      cursor: pointer;

      .counter {
        color: #A8BFD4;
      }

      .expand {
        cursor: pointer;
        float: right;

        &.reverse {
          transform: rotate(180deg);
        }
      }
    }

    .item-list {
      margin-top: 22px;

      .item {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #1D6AFF;
        margin-bottom: 16px;

        .checkbox-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          width: 24px;
          height: 24px;
          border: 1px solid #1D6AFF;
          margin-right: 16px;
        }

        .checked {
          background-color: #B1C8F5;
        }

        span {
          font-size: 12px;
        }
      }
    }

  }

  .button-panel {
    display: flex;
    justify-content: space-around;
    position: fixed;
    bottom: 0;
    margin-bottom: 10px;

    button {
      background-color: #F0F5FF;
      border-radius: 10px;
      width: 131px;
      border: none;
      outline: none;
      font-size: 12px;
      padding: 10px 5px 10px 5px;

      &:nth-child(2) {
        margin-left: 10px;
      }

      svg {
        margin-right: 7px;
      }

    }

  }

}
