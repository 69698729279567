.notifications {
  .dropdown {
    position: relative;
  }
  .counter {
    position: absolute;
    bottom: -5px;
    right: -5px;
    color: white;
    background-color: #FF9D9D;
    border-radius: 10px;
    border-color: white;
    border: 1px solid;
    width: 20px;
    height: 20px;
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dropdown-menu {
    min-width: 327px;
    padding-left: 12px;
    padding-right: 12px;
    max-height: 90vh;
    overflow-y: auto;
  }

  .header {
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .show-more {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 19px;
    padding-bottom: 19px;
    cursor: pointer;
    svg {
      margin-left: 13px;
    }
  }

  .notification {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    position: relative;

    .close {
      color: #C8C8C8;
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      margin-left: 10px;
      margin-right: 19px;
      &.red {
        color: #FF6868;
      }
      &.green {
        color: lightgreen;
      }
      &.yellow {
        color: orange;
      }
      &.blue {
        color: lightblue;
      }
      &.orange {
        color: orange;
      }
    }

    .content {
      .header {
        font-weight: bold;
        padding-top: 4px;
        padding-bottom: 4px;
        justify-content: flex-start;
      }
      font-weight: 400;
      margin-top: 10px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;

      .bold {
        font-weight: bold;
      }

    }

  }

}