.dashboard-page {
  margin: 0;

  .left-panel {
    display: flex;
    flex-direction: row;
    //justify-content: space-around;
  }

  .calendar-filter-panel-arrow {
    position: absolute;
    z-index: 9999;
    left: 28px;
    color: #FFA800;
    cursor: pointer;
    transition: transform 0.5s;
    transform: rotate(180deg);

    &.sidebaropen {
      left: 235px;
    }

    &.rotated {
      transform: rotate(0deg);
    }
  }

  .calendar {
    height: 100%;
    margin: 14px;
    border-radius: 16px;
    box-shadow: 0px 0px 23px rgba(180, 204, 250, 0.36);
  }

  .fc {
    height: 100%;
    color: #8AB2FF;
    padding: 16px;
    font-size: 14px;
    //.fc-v-event {
    //  background-color: #B5CBF1 !important;
    //  box-shadow: 0px 27px 80px rgba(46, 54, 139, 0.07), 0px 11.28px 33.4221px rgba(46, 54, 139, 0.0503198), 0px 6.0308px 17.869px rgba(46, 54, 139, 0.0417275), 0px 3.38082px 10.0172px rgba(46, 54, 139, 0.035), 0px 1.79553px 5.32008px rgba(46, 54, 139, 0.0282725), 0px 0.747159px 2.21381px rgba(46, 54, 139, 0.0196802);
    //  border-radius: 10px;
    //  border: none;
    //  color: #0048B2 !important;
    //  padding-top: 5px;
    //  padding-left: 5px;
    //  .fc-event-title, .fc-event-time {
    //    color: #0048B2 !important;
    //  }
    //}

    .fc-col-header-cell-cushion {
      text-decoration: none;
      font-size: 9px;
    }

    .fc-header-toolbar {
      display: none;
    }

    .fc-scrollgrid {
      border: none !important;
    }

    .fc-timegrid-slot {
      border: 1px solid #BFD5FF !important;
    }

    .fc-timegrid-col {
      border: 1px solid #BFD5FF !important;
    }

    .fc-timegrid-slots {
      tbody {
        tr {
          td:first-child {
            border: none !important;
          }
        }
      }
    }

    .fc-timegrid-cols {
      tr {
        td:first-child {
          border: none !important;
        }
      }
    }
  }

  .calendar-navigation {
    width: 100%;
    align-items: center;
    justify-content: center;
    .add-btn {
      margin-left: 36px;
      min-width: 120px;
      outline: none;
      border-radius: 5px;
      border: none;
      height: 44px;
      background-color: #1D6AFF;
      color: white;
      padding: 10px 16px;

      svg {
        color: #5F9FFF;
        margin-right: 10px;
      }
    }

    .today {
      margin-right: 10px;
      height: 28px;
      background-color: #F2F7FF;
      color: #5F9FFF;
      font-size: 14px;
      font-weight: 500;
      outline: none;
      border-radius: 5px;
      border: none;
      padding-left: 12px;
      padding-right: 12px;

      svg {
        color: #5F9FFF;
      }

    }

    .current-date {
      color: #5F9FFF;
      font-size: 18px;
      line-height: 100%;
      margin-left: 10px;
      margin-right: 10px;
    }

    .prev, .next {
      outline: none;
      border: none;
      background-color: white;
      color: #5F9FFF;
    }

    .calendar-panel {
      width: 100%;
      align-items: center;
      justify-content: center;
    }

    .select-wrapper {
      background-color: #E9F1FD;
      padding: 4px 12px 4px 7px;
      border-radius: 5px;

      select {
        outline: none;
        border: none;
        background-color: #E9F1FD;
        border-radius: 5px;
        color: #5F9FFF;

        option {
          border: none;
        }
      }

    }

    .calendar-type {
      margin-top: -6px;
      .nav-link {
        background-color: #E9F1FD;
        border-radius: 5px;
      }
      span {
        color: #5F9FFF;
        height: 28px;
        max-height: 28px;
        padding-top: 3px;
        padding-left: 4px;

        a {
          font-size: 14px;
          display: flex;
          align-items: center;
          padding: 0;
        }
      }

      .dropdown-menu-lg {
        border-radius: 4px;
        border: 0.2px solid #97BAFF;
        box-shadow: 0 0.375647px 6.01034px rgba(85, 101, 171, 0.06);
        max-width: 50px;
        min-width: 110px;

        button {
          outline: none;
          color: #5F9FFF;
          margin: 4px;
          height: 30px;
          display: flex;
          align-items: center;
          padding: 5px;
          cursor: pointer;
          font-size: 12px;
          width: 100px;

          &.hidden {
            display: none;
          }

          &:hover {
            background-color: #F1F6FE;
          }
        }
      }
    }
  }

  .calendar-event {
    .time {
      font-size: 8px;
    }
    .facility {
      margin-top: 3px;
      font-weight: bold;
      font-size: 9px;
    }
    .service {
      font-size: 9px;
      margin-top: 3px;
    }
  }

  .fc-timegrid-now-indicator-arrow {
    border-color: #FF8F8F !important;
    border-width: 0;
    background-color: #FF8F8F;
    transform: rotate(45deg);
    width: 5px;
    height: 5px;
    margin-top: -2.5px;
    margin-left: 1px;
  }

  .fc-day-today {

    .fc-timegrid-now-indicator-container {
      left: -10000px !important;
      width: 20000px;

      .fc-timegrid-now-indicator-line {
        border-color: #FF8F8F;
        border-style: dashed;
      }
    }
  }

  .day-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 300;
    color: #8AB2FF;

    span:first-child {
      font-size: 9px;
    }

    span:nth-child(2) {
      font-size: 18px;
      font-weight: 600;
    }

    &.active {
      span:nth-child(2) {
        background-color: #DFEAFF;
        border-radius: 15px;
        width: 30px;
        height: 26px;
        text-align: center;
      }
    }
  }

}