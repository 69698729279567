.login-page {
  min-height: 700px;
  display: flex;
  flex-direction: column;

  .back {
    color: #0048B2;
    padding-bottom: 50px;
    text-decoration: none;
    svg {
      margin-right: 10px;
    }
  }

  .description {
    color: #0048B2;
    text-align: center;
  }

  .title {
    color: #0048B2;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: column;

    .reset-password {
      font-size: 12px;
      font-weight: 500;
      float: right;
      color: #0057FF;
      text-decoration: underline;
    }

    button {
      margin-bottom: 20px;
    }
  }

  .login-as {
    border-top: 1px solid #DDDDDD;
    position: relative;
    justify-content: center;
    align-items: center;
    padding-top: 25px;

    span.header {
      color: #DDDDDD;
      position: absolute;
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      top: -11px;
      background-color: white;
      left: 71px;
      padding-left: 10px;
      padding-right: 10px;
    }

    button {
      margin-bottom: 20px;
    }
  }

  .terms-text {
    color: #828282;
    font-size: 10px;
    line-height: 15px;
    font-weight: 500;
    letter-spacing: 0.05em;
  }

  .terms-checkbox {
    .MuiFormControlLabel-label {
      font-weight: 500;
      font-size: 10px;
      line-height: 24px;
      letter-spacing: 0.05em;
      color: #828282;
    }
  }

  .signup {
    button[type="submit"] {
      margin-top: 0;
    }
  }

  .checkbox-wrapper {
    border-color: #C4C4C4;
  }

  .checkbox-label {
    font-size: 10px;
    line-height: 24px;
    letter-spacing: 0.05em;
    color: #828282;
  }

  .extra {
    color: blue;
  }
}