.objectpage {
  display: flex;
  flex-direction: column;
  margin-top: 19px;
  margin-left: 14px;
  margin-right: 40px;

  .menu {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    margin-left: 10px;
    width: 100%;

    .menu-items-container {
      flex-grow: 1;
      .menu-items {
        display: flex;
        flex-direction: row;
        height: 52px;
        font-weight: 600;
        font-size: 16px;
        line-height: 32px;
        color: #6982B4;
        flex-grow: 1;
        border-bottom: 1px solid #B6CFFF;
        width: fit-content;

        .menu-item {
          padding-bottom: 10px;
          padding-top: 10px;
          a {
            text-decoration: none;
            color: inherit;
          }

          &:not(:last-child) {
            margin-right: 34px;
          }

          cursor: pointer;

          &.active {
            color: #0057FF;
            letter-spacing: 0.01em;
            border-bottom: 2px solid;
          }
        }
      }
    }

    .remove-object {
      .MuiButton-root {
        font-weight: 600;
      }
    }
  }
}