.card-container {
  .card-title {
    color: #003CB0;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
  }

  .card {
    color: #0038B0;
    min-height: 300px;
    background: #FFFFFF;
    border: 1px solid #F7F7F7;
    box-sizing: border-box;
    box-shadow: 0 0 5px rgba(0, 87, 255, 0.03), 0 25px 35px rgba(48, 81, 147, 0.04);
    border-radius: 10px;
    padding-left: 25px;
    padding-right: 25px;

    .card-header {
      background: #FFFFFF;
      color: #003CB0;
      font-weight: 600;
      font-size: 16px;
      line-height: 32px;
      text-align: center;
      border-bottom: 1px #F0F0F0 solid;
      padding-top: 30px;
      padding-bottom: 30px;
      display: flex;
      flex-direction: column;

      span.description {
        font-weight: 300;
        font-size: 12px;
        letter-spacing: 0.01em;
        line-height: 16px;
      }
    }

    .card-footer {
      border-top: 1px #F0F0F0 solid;
      background: #FFFFFF;
      display: flex;
      flex-direction: row;
      padding-top: 20px;
      padding-bottom: 20px;
      justify-content: space-between;

      button {
        margin-left: 10px;
        margin-right: 10px;
        flex-grow: 1;
      }
    }

  }
}