.modal-dialog {
  box-shadow: 0 0 5px rgba(0, 87, 255, 0.05), 0 25px 35px rgba(48, 81, 147, 0.08);

  &.normal {
    .modal-body {
      min-height: 300px;
    }
  }

  &.confirmation {
    .modal-body {
      text-align: center;
    }
  }

  .modal-content {
    border-radius: 12px;
    border:none;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 0;
    color: #0048B2;
    position: relative;

    .modal-header {
      padding-top: 40px;
      display: flex;
      justify-content: center;
      color: #0048B2;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 32px;
      border-bottom: 1px solid #F0F0F0;
      span {
        color: #0048B2 !important;
      }

      svg.close {
        color: #C8C8C8;
        cursor: pointer;
        position: absolute;
        right: 22px;
        top: 22px;
      }
    }

    .modal-footer {
      border-top: none;
      display: flex;
      justify-content: center;
      padding-bottom: 48px;

      button {
        flex: 1;

        &:first-child {
          margin-right: 20px;
        }
      }
    }
  }
}

.modal-backdrop {
  background: rgba(132, 174, 255, 0.25) !important;
  opacity: 1 !important;
}

.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

/*Right*/
.modal.right.fade .modal-dialog {
  right: -320px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.show .modal-dialog {
  right: 0;
}